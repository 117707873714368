<template>
  <v-row   align="center" class="range-row ma-0 pl-3">
    <v-col md="2"> <v-row class="ma-0 pa-0" ><v-col style="flex-grow: 0;">{{range.display }}</v-col><v-col v-if="range.id != -1" @click="onDelete" style="flex-grow: 0; padding-left: 0px;"><div class="row--delete"/></v-col></v-row></v-col>
    <v-col md="1">
      <v-text-field v-model="start" hide-details color="#93BD20" label="From" @blur="changed"></v-text-field>
    </v-col>
    <v-col md="1">
      <v-text-field v-model="end" hide-details color="#93BD20" label="To" @blur="changed"></v-text-field>
    </v-col>
    <v-col md="3" class="errorText">
      {{ errorText }}
    </v-col>
  </v-row>
</template>

<script>
import _ from "lodash"

export default {
    name: "RangeRow",
    props: ["range"],
    data() {
        return {
            start: 0,
            end: 0,
            errorText: ""
        };
    },
    methods: {
        onDelete() {
            this.$emit("delete", this.range.id)
        },
        changed() {
            if (!this.validate()) return
            this.$emit("change", {
                id: this.range.id,
                start: this.start,
                end: this.end
            });
        },
        validate() {
            if (_.isNaN(parseInt(this.start))) {
                this.errorText = "The range you entered is invalid" //"Range starting value must be a number."
                return false
            }
            if (_.isNaN(parseInt(this.end))) {
                this.errorText = "The range you entered is invalid" //"Range ending value must be a number."
                return false
            }

            let start = parseInt(this.start)
            let end = parseInt(this.end)

            if (end < start || end == start) {
                this.errorText = "The range you entered is invalid" //"Range ending value must be larger than starting value."
                return false
            }
      

            this.errorText = ""
            return true
        },
    },
    mounted() {
        this.start = this.range.start;
        this.end = this.range.end;
    },
    watch: {
        range() {
            this.start = this.range.start;
            this.end = this.range.end;
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/mixins";

.range-row {
  border-bottom: 1px solid rgba(0,0,0,.25);
  padding-top: 0px;
  padding-bottom: 0px;
}
.errorText {
  color: #ff1744;
}
.row {

  &--delete {
    @include background-image("", "delete.svg", "../img/");
    background-size: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }
}
</style>