var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { staticClass: "range-row ma-0 pl-3", attrs: { align: "center" } },
    [
      _c(
        "v-col",
        { attrs: { md: "2" } },
        [
          _c(
            "v-row",
            { staticClass: "ma-0 pa-0" },
            [
              _c("v-col", { staticStyle: { "flex-grow": "0" } }, [
                _vm._v(_vm._s(_vm.range.display)),
              ]),
              _vm.range.id != -1
                ? _c(
                    "v-col",
                    {
                      staticStyle: { "flex-grow": "0", "padding-left": "0px" },
                      on: { click: _vm.onDelete },
                    },
                    [_c("div", { staticClass: "row--delete" })]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { md: "1" } },
        [
          _c("v-text-field", {
            attrs: { "hide-details": "", color: "#93BD20", label: "From" },
            on: { blur: _vm.changed },
            model: {
              value: _vm.start,
              callback: function ($$v) {
                _vm.start = $$v
              },
              expression: "start",
            },
          }),
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { md: "1" } },
        [
          _c("v-text-field", {
            attrs: { "hide-details": "", color: "#93BD20", label: "To" },
            on: { blur: _vm.changed },
            model: {
              value: _vm.end,
              callback: function ($$v) {
                _vm.end = $$v
              },
              expression: "end",
            },
          }),
        ],
        1
      ),
      _c("v-col", { staticClass: "errorText", attrs: { md: "3" } }, [
        _vm._v(" " + _vm._s(_vm.errorText) + " "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }