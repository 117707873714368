<template>
  <v-row  >
    <v-col v-for="item in items" :key="item.locationID">
      <v-checkbox color="#72980a" hide-details ml-1 v-model="item.selected" class="switch" :label="item.label" @change="update(item)"></v-checkbox>
    </v-col>
  </v-row>
</template>

<script>
export default {
    name: "SectionTableMatrixRow",
    props: ['items'],
    methods: {
        update(item) {
            this.$emit('change', item)
        }
    },
}
</script>

<style lang="scss" scoped>
.switch {
  margin-left: 10px;
}
</style>