// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/delete.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".range-row[data-v-7861a7c4] {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.25);\n  padding-top: 0px;\n  padding-bottom: 0px;\n}\n.errorText[data-v-7861a7c4] {\n  color: #ff1744;\n}\n.row--delete[data-v-7861a7c4] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: 20px;\n  cursor: pointer;\n  width: 20px;\n  height: 20px;\n  margin-top: -2px;\n}", ""]);
// Exports
module.exports = exports;
