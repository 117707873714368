var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    _vm._l(_vm.items, function (item) {
      return _c(
        "v-col",
        { key: item.locationID },
        [
          _c("v-checkbox", {
            staticClass: "switch",
            attrs: {
              color: "#72980a",
              "hide-details": "",
              "ml-1": "",
              label: item.label,
            },
            on: {
              change: function ($event) {
                return _vm.update(item)
              },
            },
            model: {
              value: item.selected,
              callback: function ($$v) {
                _vm.$set(item, "selected", $$v)
              },
              expression: "item.selected",
            },
          }),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }